/*
 * @Author: Fenngding
 * @Date: 2019-09-04 09:13:28
 * @LastEditors: wuqi
 * @LastEditTime: 2021-12-25 15:15:23
 * @Email: fenngding@live.com
 * @Description:
 */
//引入公共样式重置
import "./styles/reset.scss";
//引入动态加载js的库
import script from "scriptjs";
//引入enfry组件库
let iconUrls = [
  "//at.alicdn.com/t/font_1931744_qdgugge0qz.js", // 1-首页用户端
  "//at.alicdn.com/t/font_1931807_gy8pc2lr43d.js", // 3-沟通用户端
  "//at.alicdn.com/t/font_1931822_nmkzwpkfzaj.js", // 4-分析建模用户端
  "//at.alicdn.com/t/font_1931836_5bf3ojontil.js", // 5-个人设置用户端
  "//at.alicdn.com/t/font_1931891_zvw2wwvka3j.js", // 7-我的票夹用户端
  "//at.alicdn.com/t/font_1931912_oaicysemgoo.js", // 8-公司圈用户端
  "//at.alicdn.com/t/font_1931919_m478vpfrhsn.js", // 9-商旅管理用户端
  "//at.alicdn.com/t/font_1931927_j9rqenq0awp.js", // 10- 文档管理用户端
  "//at.alicdn.com/t/font_1931930_9bzhqm5i79e.js", // 11-工资管理用户端
  "//at.alicdn.com/t/font_1931936_vk0xb1p859g.js", // 12-魔钥资源用户端
  "//at.alicdn.com/t/font_1931941_iqv2hgo1oes.js", // 13-自定义资源用户端
  "//at.alicdn.com/t/font_1931950_jlpou5mex1.js", // 14-接口平台用户端
  "//at.alicdn.com/t/font_1931952_41y0n9mg55.js", //15-HR管理用户端
  "//at.alicdn.com/t/font_1931956_jgre2uicvse.js", // 16-企业征信用户端
  "//at.alicdn.com/t/font_1931957_dqvqirmq804.js", //17-智能核算用户端
  "//at.alicdn.com/t/font_1931960_wgqrazergeh.js", // 18-税务管理用户端
  "//at.alicdn.com/t/font_1931963_mvhndn19zwm.js", // 19-文件夹用户端
  "//at.alicdn.com/t/font_1931968_yttf16uxz38.js", // 21-帮助中心用户端
  "//at.alicdn.com/t/font_1931971_xiofdaj69eo.js", // 23-主题建模用户端
  "//at.alicdn.com/t/font_1931972_4j0xrwo026m.js", // 24-方案中心用户端
  "//at.alicdn.com/t/font_2384709_rqfwblc9oqg.js", //26-法律知识库用户端
  "//at.alicdn.com/t/font_2504914_rgtjgviq8ym.js", // 公用27-银行图标
  "//at.alicdn.com/t/font_1931979_338tp8mrhsr.js", // 公用-用户端icon
  "//at.alicdn.com/t/font_2711188_vrifazhxqrs.js", // 方案中心-用户端icon
  "//at.alicdn.com/t/font_2380352_yelr1qlltmn.js", // 邮件用户端
  "//at.alicdn.com/t/font_1931885_bepgsqyb4dh.js" // 6-业务建模用户端
];
// 以前重构的图标, 先注释掉
// let iconUrls = [
//   "//at.alicdn.com/t/font_1832894_fx09762nclo.js", // 管理端税务管理
//   "//at.alicdn.com/t/font_1605105_urlg4lh65wo.js", // 管理端智能核算
//   "//at.alicdn.com/t/font_1483608_dyizb9nrbjs.js", // 用户端沟通
//   "//at.alicdn.com/t/font_1481193_ksmpbagrlhc.js", // 接口管理
//   "//at.alicdn.com/t/font_1479435_ao8etvo946t.js", // 工资管理
//   "//at.alicdn.com/t/font_1477206_ok4w6ukgo1.js", // 办公管理-hr管理
//   "//at.alicdn.com/t/font_1454943_ok2hy0znzx8.js", // 日常维护
//   "//at.alicdn.com/t/font_1448627_01so7wxoceh1.js", // 个人设置
//   "//at.alicdn.com/t/font_1438324_ixch4lei80c.js", // 公司圈
//   "//at.alicdn.com/t/font_1431835_egj7qdz0im.js", // 首页
//   "//at.alicdn.com/t/font_1381440_rirr3ufvtop.js", // 我的票夹
//   "//at.alicdn.com/t/font_1381297_yu4sd7miam.js", // 会议室预定
//   "//at.alicdn.com/t/font_1364418_undutohgl1.js", // 文档管理
//   "//at.alicdn.com/t/font_1751325_kttwmzr136.js", //用户端我的文件夹
//   "//at.alicdn.com/t/font_1361467_gltyhewd27c.js", // 用户端商旅管理
//   "//at.alicdn.com/t/font_1605105_04h8l857ez7.js", //用户端智能核算
//   "//at.alicdn.com/t/font_1336773_hkanhpcr37k.js", // 管理端-入口图标
//   "//at.alicdn.com/t/font_1310643_ddxq9m5u61i.js", // 通知建模
//   "//at.alicdn.com/t/font_1310642_6j6lvxcky86.js", // 管理建模
//   "//at.alicdn.com/t/font_1310640_3csrmbrbsqq.js", // 分析建模
//   "//at.alicdn.com/t/font_1310638_j4njz598lc.js", // 流程建模
//   "//at.alicdn.com/t/font_1310636_tg86prr1sjr.js", // 业务建模
//   "//at.alicdn.com/t/font_1310608_o696n28pd4f.js", // 基础数据
//   "//at.alicdn.com/t/font_1528122_qx2zcva4j6.js", //超级管理员
//   "//at.alicdn.com/t/font_1301101_ldck6cbf5ei.js", // 公用icon
// ];
//动态加载iconfont.js的图标文件
iconUrls.forEach((item) => {
  script(item);
});
